import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import * as axios from 'axios'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Grid, IconButton, MenuItem, Menu, Tooltip, Chip } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Weather from './weather';
import Box from '@material-ui/core/Box';
import AircraftThumbnail from "./AircraftThumbnail";
import AnalysisNotam from "./AnalysisNotam";
import { useSelector } from 'react-redux'

import {
  VisibilityOff,
  Visibility,
  MapOutlined,
  AssessmentOutlined,
  Warning,
} from '@material-ui/icons';
import { useHistory } from "react-router-dom";
import RestrictedMLAccess from "../lib/RestrictedMLAccess";

const WEBAPI = process.env.REACT_APP_WEB_API;

const PATTERN_LABELS = {
  'possible_turn_around': 'Possible Turn Around',
  'runway_crossing_no_taxiing': false,
  'runway_crossing_taxiing': 'Runway-Runway Taxiing',
  'no_runway_crossing': false,
  'possible_touch_and_go': 'Possible Touch and Go / Fly By',
}

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    // margin: theme.spacing(2),
  },
  flightgrid: {
    flexGrow: 1
  },
  rightSpace: {
    textAlign: 'right'
  },
  actions: {
    marginLeft: 'auto',
    textAlign: 'right'
  }
}));

export default function LandingBanner(props) {
  const classes = useStyles();
  const history = useHistory();

  const [airportName, setAirportName] = useState('')
  const [airportCode, setAirportCode] = useState('')
  const [landingDate, setLandingDate] = useState('')
  const [landingRunway, setLandingRunway] = useState('')
  const [icao, setIcao] = useState('')
  const [callsign, setCallsign] = useState('')
  const [imageUrl, setImageUrl] = useState('')

  const [make, setMake] = useState('')
  const [model, setModel] = useState('')
  const [registration, setRegistration] = useState('')
  const [hidden, setHidden] = useState(props.prophidden);
  const [pattern, setPattern] = useState(props.pattern);
  console.log('pattern:', pattern)

  const [alerts, setAlerts] = useState(props.alerts || 0)

  // const [owner, setOwner] = useState('')
  const root = useSelector((state) => state.root.value)
  const role = useSelector((state) => state.role.value)

  useEffect(() => {
      setAirportName(props.airportname)
      setAirportCode(props.airportcode)
      setLandingDate(props.created)
      setLandingRunway(props.landingrunway)
      setIcao(props.icaoaddress)
      setCallsign(props.callsign)

      axios.get(`${WEBAPI}/aircraft/${props.icaoaddress}`)
      .then((res) => {
        // console.log(res.data)
        setMake(res.data.make)
        setModel(res.data.model)
        setRegistration(res.data.registration)
        // setOwner(res.data.owner)

        setImageUrl(res.data.image_url)
      })
      .catch((err) => {
        console.log(err)
      })

  }, [props]);


  const handleHide = () => {
    let currentHideStatus = hidden;
    
    axios.post(`${WEBAPI}/landing/hide`, {hidden: !currentHideStatus, id: props.id}).then((res) => {
      if ( res.data.success === true ) {
        setHidden(!currentHideStatus)
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  return ( 
    <div className={classes.flightgrid} >
      <Card className={classes.root}>
        <CardContent>

          <Grid container spacing={2}>

            <Grid item xs={6}>
              <Box flexWrap='wrap' className={classes.header} >
                  <Weather code={airportCode} ts={landingDate} id={props.id} setSourceWeatherData={props.setSourceWeatherData}  /> 
                  <br />

                  <Box>
                  <Typography color="textSecondary" display='inline'>
                    Airport:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" title={airportName} display='inline'>
                    {airportCode}
                  </Typography>
                  &nbsp;&nbsp;

                  <Typography color="textSecondary" display='inline'>
                    Date:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" display='inline'>
                    {(new Date(landingDate)).toLocaleDateString([], { year:  'numeric', month: '2-digit', day:   '2-digit' })}&nbsp;&nbsp;
                    {(new Date(landingDate)).toLocaleTimeString([], { hour: '2-digit', minute:'2-digit', hour12: true })}
                  </Typography>
                  &nbsp;&nbsp;

                  <Typography color="textSecondary" display='inline'>
                  Runway:&nbsp;
                  </Typography>
                  <Typography color="textSecondary" variant="subtitle2" display='inline'>
                    {landingRunway}
                  </Typography>
                  </Box>
              </Box>

              <Grid container style={{marginTop: 10}}>
                <Grid item xs={12} lg={4} >
                  <AircraftThumbnail thumbnailURL={imageUrl} />

                  {
                    registration ?
                    <Box>
                      <Typography color="textSecondary" display='inline'>
                      Registration:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {registration}
                      </Typography>
                    </Box>
                    :
                    null
                  }

                  <Box>
                    <Typography color="textSecondary" display='inline'>
                    ICAO:&nbsp;
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2" display='inline'>
                      {icao}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography color="textSecondary" display='inline'>
                    Callsign:&nbsp;
                    </Typography>
                    <Typography color="textSecondary" variant="subtitle2" display='inline'>
                      {callsign}
                    </Typography>
                  </Box>

                  {
                    make ?
                    <Box>
                      <Typography color="textSecondary" display='inline'>
                      Make:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {make}
                      </Typography>
                    </Box>
                    :
                    null
                  }

                  {
                    model ?
                    <Box>
                      <Typography color="textSecondary" display='inline'>
                      Model:&nbsp;
                      </Typography>
                      <Typography color="textSecondary" variant="subtitle2" display='inline'>
                        {model}
                      </Typography>
                    </Box>
                    :
                    null
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} className={classes.rightSpace}>
              <AnalysisNotam id={props.id} landingrunway={props.landingrunway} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={4} style={{marginTop: '10px'}}>
              {
                (pattern !== '' &&  pattern !== null) && root ? 
                  pattern?.split(",").map((item, index) => {
                    return PATTERN_LABELS[item] ? <><Chip key={index} color="secondary" size="small" variant="outlined" label={PATTERN_LABELS[item]} />&nbsp;&nbsp;</> : null
                  }) : null
              }
            </Grid>
            <Grid item className={classes.actions} xs={3}>
              <Tooltip title="Navigate to Landing">
                <IconButton children={<MapOutlined /> } onClick={() => history.push('/landing/'+props.id)} />
              </Tooltip>

              <Tooltip title="Navigate to Analysis">
                <IconButton children={<AssessmentOutlined />} onClick={() => history.push('/analysis/'+props.id)}  />
              </Tooltip>

              <RestrictedMLAccess>
                { alerts > 0 ?
                  <Tooltip title="Navigate to Alerts">
                    <IconButton children={<Warning /> } onClick = {() => history.push('/landingalerts/'+props.id) } />
                  </Tooltip> : ''
                }
              </RestrictedMLAccess>

              {
                (root || role === "admin") ? (!hidden ?
                <Tooltip title="Hide Landing"><IconButton children={<Visibility />} onClick={() => handleHide()} /></Tooltip>:
                <Tooltip title="Show Landing"><IconButton children={<VisibilityOff />} onClick={() => handleHide()}  color="primary" /></Tooltip>)
                : ''
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
    </div>

    );
}
 
